import React, { useEffect, useState, useRef } from "react";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import { ThreeDots } from "react-loader-spinner";

function Raftingdetails() {
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [productdata, setProductData] = useState([]);
  const newArrayList = [];
  const productdata2 = {
    NumberOfFieldsView: 50,
    filters: { "Category Name": "White Water Rafting" },
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 3,
  };
  const [isLoader, setIsLoader] = useState(true);

  //   setTimeout(() => {
  //     setIsLoader(false);
  //   }, 3000);
  function ProductData1() {
    //setIsLoader(true);
    fetch("https://api.dialerp.com/api/api/getTemplateDataList", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        // "oq+R3B3lt5I1bybDbHvscBRQDkBF8ek9cciW21c2Lza9XNAKeGE13bKO1giqxZ/uL2fM+m2QLjbSkMpBo3qM9DqDqDv7SojZA+5WpHVMh+tsnC9HUXan4CKJ8/bI4/2Lqa7DMzLXd1LqL9KA1iuY8g==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(productdata2),
    }).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
      });
      setIsLoader(false);
    });
  }

  useEffect(() => {
    ProductData1();
  }, [0]);

  productdata?.response?.records?.forEach((obj) => {
    if (!newArrayList.some((o) => o[5].value === obj[5].value)) {
      newArrayList.push({ ...obj });
    }
  });
  function CourseBooking(obj) {
    localStorage.removeItem("Booking-Info");
    localStorage.removeItem("Booking-Type");
    localStorage.setItem("Booking-Info", JSON.stringify(obj));
    localStorage.setItem("Booking-Type", "Aqua Adventure");
    const userdelete1 = JSON.parse(localStorage.getItem("user-Studentinfo"));
    if (userdelete1 != null) {
      window.location.href = "/student-admin/book-course";
    } else {
      window.location.href = "/student-admin/login";
    }
  }

  const titleRef = useRef();
  function handleBackClick(id) {
    //debugger
    const element = document.getElementById(`${id}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <>
      {isLoader && (
        <div id="loadingg">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#fff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <Header />
      <div className="wrapper coursedetails">
        <div className="bradcumb-area style-wwr">
          <div className="container"></div>
        </div>
      </div>

      <div className="container pb-200">
        <div className="row">
          <div className="col-sm-12">
            <div className="gallery">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <div name="boxes" className="boxselect crspan">
                        {newArrayList?.map((dd, i) => {
                          return (
                            <span
                              value={"ad" + i}
                              onClick={() => {
                                handleBackClick("ad" + i);
                              }}
                            >
                              {dd[5].value}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="single-camp-hi">
                      <img src="images/adventure/570x250/rafting.png" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 disf">
                    <div>
                      <h4>White Water Rafting Courses</h4>
                      <p className="para">
                        White water rafting stands as an exhilarating aquatic
                        adventure, involving the skilful navigation of
                        inflatable rafts through turbulent river waters. Beyond
                        the rush of adrenaline, this activity offers a
                        remarkable opportunity to forge a connection with nature
                        and relish the excitement of collaborative teamwork.
                      </p>
                      <p className="para">
                        Within this program, students delve into the
                        geographical intricacies of rivers, engage in
                        operational procedures, and navigate the challenging
                        rapids of the Kameng River. If navigating twists and
                        turns presents a perpetual challenge, the Basic and
                        Intermediate White Water Rafting courses beckon.
                        Covering everything from river geography to hazard
                        comprehension and rescue techniques, this program caters
                        to anyone seeking a profound understanding of
                        White-Water Rafting. The meticulously designed modules
                        of the Basic and Intermediate courses empower students
                        to immerse themselves in this river sport, gaining
                        firsthand experience and deepening their knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {newArrayList?.map((productitem122, i) => {
              return (
                <div key={i}>
                  <div
                    class={"box all wow fadeInDown ad" + i}
                    id={"ad" + i}
                    ref={titleRef}
                  >
                    <div className="camping-hi-area">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="section-title sec-t">
                              <h5>{productitem122[5].value}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="dis-alpine section-title">
                              <div className="sin-sis-alpine-wrap">
                                <div className="row">
                                  <div className="col-sm-2">
                                    <div className="single-alpine">
                                      <h4>Course Fee</h4>
                                      <h3>₹{productitem122[12].value}/-</h3>
                                    </div>
                                  </div>
                                  <div className="col-sm-2">
                                    <div className="single-alpine">
                                      <h4>Duration</h4>
                                      <h3>{productitem122[6].value}</h3>
                                    </div>
                                  </div>
                                  <div className="col-sm-1">
                                    <div className="single-alpine">
                                      <h4>Age</h4>
                                      <h3>{productitem122[14].value}</h3>
                                    </div>
                                  </div>
                                  <div className="col-sm-1">
                                    <div className="single-alpine">
                                      <h4>Seats</h4>
                                      <h3>{productitem122[7].value}</h3>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="single-alpine">
                                      <h4>Season </h4>
                                      <h3>{productitem122[8].value}</h3>
                                    </div>
                                  </div>
                                  <div className="col-sm-2">
                                    <div className="single-alpine">
                                      <h4>Reviews</h4>
                                      <ul>
                                        <li>
                                          <i className="fas fa-star active"></i>
                                        </li>
                                        <li>
                                          <i className="fas fa-star active"></i>
                                        </li>
                                        <li>
                                          <i className="fas fa-star active"></i>
                                        </li>
                                        <li>
                                          <i className="fas fa-star active"></i>
                                        </li>
                                        <li>
                                          <i className="fas fa-star "></i>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center pb-50">
                          <div className="col-lg-12">
                            <div className="table-responsive mb-30">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <p>Sr.No.</p>
                                    </th>
                                    <th scope="col">
                                      <p>Course Dates </p>
                                    </th>
                                    <th scope="col">
                                      <p>Course Fees </p>
                                    </th>
                                    <th scope="col" className="mobilenone">
                                      <p>Capacity</p>
                                    </th>
                                    <th scope="col" className="mobilenone">
                                      <p>Age</p>
                                    </th>
                                    <th scope="col" className="mobilenone">
                                      <p>Type</p>
                                    </th>
                                    <th scope="col">
                                      <p>Availability</p>
                                    </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productdata?.response?.records
                                    ?.filter((productitem11, i) => {
                                      // Replace the condition below with your desired filtering logic
                                      return (
                                        productitem11[5].value ===
                                        productitem122[5].value
                                      );
                                    })
                                    .sort((a, b) => {
                                      return a[9].value.localeCompare(
                                        b[9].value
                                      );
                                      //return a[9].value - b[9].value;
                                    })
									.map((filteredItem, i) => {
										// Parse currentDate and filteredItem[11].value to Date objects
										const currentDate = new Date();
										const courseEndDate = new Date(
										  filteredItem[11].value
										);
  
										// Determine if the button should be disabled
										const isBookingDisabled =
										  courseEndDate < currentDate;
  
										return (
                                      <tr key={i}>
                                        <td>{filteredItem[9].value}</td>
                                        <td>
                                          {filteredItem[10].value} -{" "}
                                          {filteredItem[11].value}
                                        </td>
                                        <td>₹{filteredItem[12].value}/-</td>
                                        <td className="mobilenone">{filteredItem[13].value}</td>
                                        <td className="mobilenone">{filteredItem[14].value}</td>
                                        <td className="mobilenone">{filteredItem[15].value}</td>
                                        <td>{filteredItem[16].value}</td>
                                        <td>

										{isBookingDisabled ? (
                                              <span
                                                className=" mb-autoo"
                                                style={{
                                                  color: "red",
                                                  padding: "0px",
                                                }}
                                              >
                                                Booking Closed
                                              </span>
                                            ) : (
                                              <>
                                                {filteredItem[16].value >
                                                "0" ? (
                                                  <button
                                                    className="btn btn-theme mb-autoo"
                                                    onClick={() =>
                                                      CourseBooking(
                                                        filteredItem
                                                      )
                                                    }
                                                  >
                                                    Book Now
                                                  </button>
                                                ) : (
                                                  <span
                                                    className="mb-autoo"
                                                    style={{
                                                      color: "red",
                                                      padding: "0px",
                                                    }}
                                                  >
                                                    Sold
                                                  </span>
                                                )}
                                              </>
                                            )}

                                          {/* {filteredItem[16].value > "0" ? (
                                            <a
                                              className="btn btn-theme mb-autoo"
                                              onClick={() =>
                                                CourseBooking(filteredItem)
                                              }
                                            >
                                              Book Now
                                            </a>
                                          ) : (
                                            <span
                                              className="btn btn-theme mb-autoo"
                                              style={{
                                                color: "red",
                                                padding: "0px",
                                              }}
                                            >
                                              Sold
                                            </span>
                                          )} */}
                                         
                                        </td>
                                        
                                      </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                            <div className="note">*Please swipe from right to left on course table for Booking.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Raftingdetails;
